<template>
  <v-dialog
    class="v-dialog"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="modal">
      <div class="modal-close" @click="onCloseClick">
        <v-icon large>
          mdi-close
        </v-icon>
      </div>
      <div class="modal-header">
        <div v-if="title.length" class="modal-header__title">
          {{ $t(title) }}
        </div>
        <c-img
          src="/assets/img/theme/modal-top-element.png"
          alt=""
          class="modal__top-element"
        />
        <c-img
          src="/assets/img/theme/modal-bottom-element.png"
          alt=""
          class="modal__bottom-element"
        />
        <div v-if="logo" class="modal-header__logo">
          <c-img
            src="/assets/img/theme/event-logo-light.png"
            class="modal-header-logo"
            alt="logo"
          />
        </div>
      </div>
      <div
        class="modal-body"
        :style="maxHeightStyle"
      >
        <div class="modal-body__content">
          <slot name="content" />
        </div>
        <slot>
          <div v-if="btnLeft.length || btnRigth.length" class="modal-body__buttons">
            <div
              v-if="btnLeft.length"
              class="button button--left"
              @click="btnLeftClick"
            >
              {{ $t(btnLeft) | upperFirst }}
            </div>
            <div
              v-if="btnRigth.length"
              class="button button--rigth"
              @click="btnRigthClick"
            >
              <span>{{ $t(btnRigth) | upperFirst }}</span>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </v-dialog>
</template>
<script>

export default {
  props: {
    logo: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    btnRigth: {
      type: String,
      default: ''
    },
    btnLeft: {
      type: String,
      default: ''
    },
    maxHeight: {
      type: Number,
      default: null
    }
  },
  computed: {
    maxHeightStyle () {
      return {
        maxHeight: this.maxHeight ? `${this.maxHeight}px` : '',
        overflowY: this.maxHeight ? 'auto' : 'unset'

      }
    }
  },
  methods: {
    btnRigthClick () {
      this.$emit('btnRigthClick')
    },
    btnLeftClick () {
      this.$emit('btnLeftClick')
    },
    onCloseClick () {
      this.$emit('onCloseModal')
      this.$attrs.value = false
    }
  }
}
</script>

  <style lang="scss">
  .v-dialog {
    max-width: max-content;
    display: flex;
    justify-content: center;
    overflow: visible !important;
    box-shadow: none !important;
  }

  .modal {
    // min-height: 378px;
    max-width: 700px;
    border-radius: 24px 24px 20px 20px;
    background: #fff;

    @include below(map_get($grid-breakpoints, sm)) {
      max-width: calc(100vw - 36px);
      max-height: calc(100vh);
      height: max-content;
    }

    .modal-header {
      background: linear-gradient(45deg, var(--main-primary-background-color), var(--main-secondary-background-color));
      // background-image: var(--modal-header-bg);
      border-radius: 20px 20px 0 0;
      background-size: cover;
      display: flex;
      justify-content: space-between;
      padding: 35px 50px;
      position: relative;

      &__title {
        font-style: italic;
        font-size: 40px;
        font-weight: bold;
        font-family: "BebasNeuePro", serif;
        line-height: 42px;
        text-transform: uppercase;
        color: #fff;
      }

      .modal__top-element {
        position: absolute;
        pointer-events: none;
        left: -55px;
        bottom: -35px;
        z-index: -1;
      }

      .modal__bottom-element {
        position: absolute;
        pointer-events: none;
        top: -58px;
        right: -67px;
        z-index: -1;
      }

      &-logo {
        width: 138px;
      }
    }

    .modal-body {
      padding-top: 18px;
      margin-bottom: 20px;
      margin-top: 20px;
      padding-left: 40px;
      padding-right: 40px;
      // padding-right: 180px;
      min-width: 520px;

      @include below(map_get($grid-breakpoints, sm)) {
        padding-top: 25px;
        padding-left: 25px;
        padding-right: 25px;
      }

      .modal-body__content {
        font-family: "BebasNeuePro", serif;
        font-size: 40px;
        text-transform: uppercase;
        font-style: italic;
        width: 256px;
        line-height: 42px;
        font-weight: 700;

        /* stylelint-disable-next-line */
        @media screen and (max-width: 992px) {
          font-size: 30px;
        }
      }

      .modal-body__buttons {
        display: flex;
        margin-top: 42px;
        margin-bottom: 20px;
      }

      .button {
        user-select: none;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 220px;
        height: 60px;
      }

      .button--left {
        // background-image: var(--modal-left-button-bg);
        background: linear-gradient(45deg, var(--main-secondary-background-color), var(--main-primary-background-color));
        color: #fff;
        cursor: pointer;
      }

      .button--rigth {
        color: #4C61CF;
      }
    }

    .button--rigth > span {
      cursor: pointer;
    }
  }
</style>
