<template>
  <footer class="footer">
    <v-container>
      <div class="footer__inner">
        <div class="footer__left">
          <div class="footer__left-logo">
            <c-img src="assets/img/theme/event-logo.png" alt="" />
          </div>
        </div>
        <div class="footer__content">
          <nav class="footer-menu">
            <v-row>
              <v-col v-if="settings && settings.eventDays && settings.eventDays.enabled" class="footer-menu__column">
                <div
                  v-for="day in eventDays"
                  :key="day.id"
                  class="footer-menu__item"
                >
                  <router-link
                    :to="`/page/day/${day.index}`"
                    class="footer-menu__item-link"
                    :class="{
                      'fm-link--live': day.isDayStart
                    }"
                  >
                    {{ $t('day') | upperFirst }} {{ day.index }}

                    <span v-if="day.isDayStart" class="fm-live footer__fm-live">
                      <img
                        :src="require('@/assets/img/svg/live.svg')"
                        alt=""
                      >
                    </span>
                  </router-link>
                </div>
              </v-col>

              <v-col>
                <div v-if="settings && settings.schedule && settings.schedule.enabled" class="footer-menu__item">
                  <router-link to="/schedule" class="footer-menu__item-link">
                    {{ $t('schedule') | upperFirst }}
                  </router-link>
                </div>
                <div v-if="settings && settings.nominations && settings.nominations.enabled" class="footer-menu__item">
                  <router-link to="/nominations" class="footer-menu__item-link">
                    {{ $t('new ranks') | upperFirst }}
                  </router-link>
                </div>
              </v-col>

              <v-col>
                <div v-if="settings && settings['my-profile'] && settings['my-profile'].enabled" class="footer-menu__item">
                  <router-link to="/my-profile" class="footer-menu__item-link">
                    {{ $t('personal area') | upperFirst }}
                  </router-link>
                </div>
                <div v-if="settings && settings.help && settings.help.enabled" class="footer-menu__item">
                  <div
                    style="cursor: pointer;
                    transition: 0.4s ease;"
                    class="footer-menu__item-link footer-menu__item-link--help"
                    @click="handleClickHelp"
                  >
                    {{ $t('help') | upperFirst }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </nav>
        </div>
        <div class="footer__right">
          <a href="#" class="footer-logo">
            <img :src="require('@/assets/img/svg/coral_logo.svg')" alt="">
          </a>
        </div>
      </div>
    </v-container>
    <modal
      v-model="helpModal"
      :max-height="400"
      logo
      @onCloseModal="helpModal = false"
    >
      <template slot="content">
        <HelpHTML />
      </template>
    </modal>
  </footer>
</template>

<script>
import Modal from '@/components/Modal'
import HelpHTML from '@/components/Help'
import businessEventService from '@/services/businessEvent'
import EventBus from '@/EventBus'
import { SETTINGS_HEADER_FOOTER_KEY } from '@/constants/settings'

export default {
  components: {
    Modal,
    HelpHTML
  },
  data: () => ({
    eventDays: [],
    helpModal: false,
    settings: null
  }),

  async created () {
    this.eventDays = await businessEventService.getEventDays()

    this.normalizeSettings(await businessEventService.getSettings(SETTINGS_HEADER_FOOTER_KEY))

    EventBus.subscribe('businessEvent:update', this.onEventUpdate)
  },

  beforeDestroy () {
    EventBus.unsubscribe('businessEvent:update', this.onEventUpdate)
  },

  methods: {
    async onEventUpdate () {
      this.eventDays = await businessEventService.getEventDays()
    },

    handleClickHelp () {
      // if (this.$route.name === 'day') {
      //   this.$tours.tour.start()
      // } else {
      this.helpModal = true
      // }
    },

    normalizeSettings (obj) {
      if (obj === undefined) return {}
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const tempKey = obj[key].name
          if (tempKey !== key) {
            const temp = obj[key]
            delete obj[key]
            obj[tempKey] = temp
          }
        }
      }
      this.settings = { ...obj }
    }
  }
}
</script>

<style lang="scss" scoped>
/* stylelint-disable */
@import '~vuetify/src/styles/styles';

.footer {
  position: relative;
  background-color: #fff;
  padding-top: 14px;

  a {
    transition: 0.4s ease;
    outline: none;
    text-decoration: none;
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(37, 37, 37, 0.3);
    padding-top: 85px;
    padding-bottom: 98px;

    @include below(map_get($grid-breakpoints, md)) {
      display: flex;
      flex-direction: column;
      padding-top: 40px;
      padding-bottom: 25px;
    }
  }

  &__content {
    flex-grow: 1;
    max-width: 52%;

    @include below(map_get($grid-breakpoints, md)) {
      display: none;
    }
  }

  &__left {
    margin-right: auto;

    &-logo {
      max-width: 235px;

      img {
        max-width: 100%;
      }

      @include below(map_get($grid-breakpoints, lg)) {
        max-width: 200px;
      }

      @include below(map_get($grid-breakpoints, md)) {
        max-width: 140px;
      }
    }
  }

  &__right {
    margin-left: auto;
    padding-left: 80px;

    @include below(map_get($grid-breakpoints, xl)) {
      padding-left: 0;
    }

    @include below(map_get($grid-breakpoints, md)) {
      display: none;
    }
  }

  &__copy {
    color: rgba(37, 37, 37, 0.6);
    line-height: 1.4;
    padding-top: 35px;
    display: none;
    white-space: pre;

    @include below(map_get($grid-breakpoints, md)) {
      display: block;
    }
  }

  &__fm-live {
    position: absolute;
    right: -45px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.footer-logo img {
  max-width: 100%;
}

.footer-logo-block {
  text-align: right;
}

.footer-menu {
  font-size: 21px;
  line-height: 1.45;
  text-transform: uppercase;

  @include below(map_get($grid-breakpoints, lg)) {
    font-size: 18px;
  }

  .footer-menu__column {
    display: flex;
    flex-direction: column;
  }

  .footer-menu__item {
    margin-bottom: 10px;
  }

  .footer-menu__item-link {
    font-family: 'BebasNeuePro', sans-serif;
    color: var(--header-footer-footer-font-color);
    display: inline-block;
    letter-spacing: 0.03em;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 5px;
    position: relative;
    text-decoration: none;
    white-space: nowrap;

    &:hover {
      background-color: rgba(63, 146, 225, 0.3);
    }

    &.fm-link--live {
      background-color: var(--main-primary-background-color);
      color: #fff;
    }
  }
}
</style>
