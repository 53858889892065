export default {
  day: 'День',
  schedule: 'Расписание',
  'new ranks': 'Новые ранги',
  'test your knowledge': 'Проверьте свои знания',
  'test passed': 'Тест пройден',
  'take the test': 'Пройти тест',
  'the broadcast schedule is indicated by moscow time (gmt +3)': 'Расписание эфиров указано по московскому времени (GMT +3)',
  'watch the recording': 'Смотреть запись',
  'forum speakers prefix': 'Спикеры',
  'platinum executives': 'Платиновые мастера',
  'silver executives': 'Серебряные мастера',
  'double silver executives': 'Дабл серебряные мастера',
  '20k executives': 'Мастера 20 000 баллов',
  executives: 'Мастера',
  'gold executives': 'Золотые мастера',
  'superior master': 'Вышестоящий мастер',
  'diamond directors': 'Бриллиантовые директора',
  'emerald directors': 'Изумрудные директора',
  'gold directors': 'Золотые директора',
  'star executives': 'Звездные мастера',
  directors: 'Директора',
  'new gold master': 'Новый Золотой мастер',
  'our new diamond master': 'Нашему новому Бриллиантовому директору',
  ge: 'ЗМ',
  dse: '2СМ',
  se: 'СМ',
  dd: 'БД',
  e: 'М',
  ed: 'ИД',
  gd: 'ЗД',
  e20: 'М20К',
  'filter by rank': 'Ранг',
  'filter by country': 'Страна',
  'filter by city': 'По городу',
  'filter by name': 'По имени',
  'no match': 'Нет совпадений',
  'all speakers': 'Все спикеры',
  december: 'Декабрь',
  january: 'Январь',
  february: 'Февраль',
  march: 'Март',
  april: 'Апрель',
  may: 'Май',
  june: 'Июнь',
  july: 'Июль',
  august: 'Август',
  september: 'Сентябрь',
  october: 'Октябрь',
  november: 'Ноябрь',
  'useful materials': 'Полезные материалы',
  download: 'Скачать',
  'personal area': 'Личный кабинет',
  help: 'Помощь',
  'edit profile': 'Редактировать профиль',
  'helpful information': 'Полезная информация',
  poll: 'Опрос',
  'just now': 'Только что',
  vote: '0 голосов | {n} голос | {n} голоса | {n} голосов',
  'leave comment': 'Написать',
  'leave comment expert': 'Написать эксперту',
  'chat expert tab text': 'Комментарии эксперта',
  'chat users tab text': 'Чат',
  'new comment': '0 новых комментариев | {n} новый комментарий | {n} новых комментария | {n} новых комментариев',
  'profile update': 'Редактировать профиль',
  'profile settings': 'Настройки профиля',
  'change photo': 'Сменить фото',
  'last name': 'Фамилия',
  'first name': 'Имя',
  save: 'Сохранить',
  cancel: 'Отменить',
  'enter your comment text': 'Введите текст комментария',
  congratulate: 'Отправить комментарий',
  congratulated: 'Прокомментировали ',
  '{n} years with coral club': '0 лет с Coral Club | {n} год с Coral Club | {n} года с Coral Club | {n} лет с Coral Club',
  'placeholder text: foresight winter 2021 broadcasts start every day at 11:00 moscow time': 'Placeholder text: Эфиры Foresight Winter 2021 стартуют каждый день в 11:00 по московскому времени',
  reply: 'Ответить',
  'send message': 'Отправить',
  'load more': 'Загрузить ещё',
  'enter your greeting text': 'Введите текст поздравления',
  'enter your message': 'Введите текст сообщения',
  'auth-welcome': 'Добро пожаловать на',
  eventStarted: 'Мероприятие пройдет',
  login: 'Ваш клубный номер',
  password: 'Пароль из письма-приглашения',
  submitButton: 'Войти',
  registration: 'Регистрация',
  'password recovery': 'Восстановление пароля',
  'your email': 'Ваш e-mail',
  'we will send a link to change your password by email': 'Мы отправим на почту ссылку на изменение пароля',
  next: 'Далее',
  'change password by {email} accepted': 'Ваша заявка на изменение пароля принята! Мы отправим Вам на почту {example@test.com} письмо со ссылкой на форму для изменения пароля',
  'log in': 'Вход',
  'action not available': 'Немного терпения',
  'only authorized users can perform this action': 'Только авторизованные пользователи могут выполнить это действие. Пожалуйста, авторизуйтесь или зарегистрируйтесь!',
  'enter a new password': 'Введите новый пароль',
  're-enter new password': 'Повторите новый пароль',
  'you have successfully changed your password': 'Вы успешно изменили свой пароль! ',
  'back to the main page': 'На главную',
  'test from': 'Тест от',
  'start test': 'Начать тест',
  'question {n} / {n}': 'Вопрос {n}/{n}',
  'next question': 'Следующий вопрос',
  'postpone test': 'Отложить тест',
  correct: 'Верно!',
  incorrect: 'Неверно!',
  congratulations: 'Поздравляем!\n Вы прошли тест!',
  'your result': 'Ваш результат',
  'test success': 'Вы правильно ответили на все вопросы! Поздравляем вас с успешным прохождением теста! ',
  'next test': 'Следующий тест',
  'test failed': 'Тест\n не пройден',
  'test fail': 'К сожалению, вы ответили неправильно на некоторые вопросы. Вы можете пересмотреть выступление снова и вернуться с новыми знаниями!',
  'please enter your information and we will send an email to your mail with a link to change your password': 'Пожалуйста, введите свои данные, и мы отправим на Вашу почту письмо с ссылкой на изменение пароля',
  'please enter your new password and then try it again': 'Пожалуйста, введите Ваш новый пароль, а затем повторите его еще раз',
  share: 'Share',
  'from june 20\' till jan 21\'': 'июль \'20 - январь \'21',
  logout: 'выйти',
  'check you knowledge': 'Проверь свои знания',
  'broadcasting will be available soon': 'Трансляция будет доступна позже',
  'are you sure want to logout': 'вы действительно хотите выйти',
  tests: 'тесты',
  'page not found': 'страница не найдена',
  'full name': 'фио',
  question: 'вопрос',
  'to vote': 'голосовать',
  test: 'Тест',
  'finish test': 'Завершить',
  right: 'Правильно',
  wrong: 'Неправильно',
  result: 'Результат',
  'all nominees': 'все номинанты',
  edit: 'редактировать',
  delete: 'удалить',
  no: 'нет',
  yes: 'да',
  translate: 'перевести',
  send: 'отправить',
  success: 'успешно',
  'platinum executive': 'платиновых мастеров | платиновый мастер | платиновых мастера | платиновых мастеров',
  '{n} platinum executive': '0 платиновых мастеров | {n} платиновый мастер | {n} платиновых мастера | {n} платиновых мастеров',
  'silver executive': 'серебряных мастеров | серебряный мастер | серебряных мастера | серебряных мастеров',
  '{n} silver executive': '0 серебряных мастеров | {n} серебряный мастер | {n} серебряных мастера | {n} серебряных мастеров',
  'gold executive': 'золотых мастеров | золотой мастер | золотых мастера | золотых мастеров',
  '{n} gold executive': '0 золотых мастеров | {n} золотой мастер | {n} золотых мастера | {n} золотых мастеров',
  'star executive': 'звездных мастеров | звездный мастер | звездных мастера | звездных мастеров',
  '{n} star executive': '0 звездных мастеров | {n} звездный мастер | {n} звездных мастера | {n} звездных мастеров',
  'double silver executive': 'дабл серебряных мастеров | дабл серебряный мастер | дабл серебряных мастера | дабл серебряных мастеров',
  '{n} double silver executive': '0 дабл серебряных мастеров | {n} дабл серебряный мастер | {n} дабл серебряных мастера | {n} дабл серебряных мастеров',
  'emerald director': 'изумрудных директоров | изумрудный директор | изумрудных директора | изумрудных директоров',
  '{n} emerald director': '0 изумрудных директоров | {n} изумрудный директор | {n} изумрудных директора | {n} изумрудных директоров',
  executive: 'мастеров | мастер | мастера | мастеров',
  '{n} executive': '0 мастеров | {n} мастер | {n} мастера | {n} мастеров',
  '20k executive': 'мастеров 20 000 баллов | мастер 20 000 баллов | мастера 200 00 баллов | мастеров 20 000 баллов',
  '{n} 20k executive': '0 мастеров 20 000 баллов | {n} мастер 20 000 баллов | {n} мастера 20 000 баллов | {n} мастеров 20 000 баллов',
  'diamond director': 'бриллиантовых директоров | бриллиантовый директор | бриллиантовых директора | бриллиантовых директоров',
  '{n} diamond director': '0 бриллиантовых директоров | {n} бриллиантовый директор | {n} бриллиантовых директора | {n} бриллиантовых директоров',
  'gold director': 'золотых директоров | золотой директор | золотых директора | золотых директоров',
  '{n} gold director': '0 золотых директоров | {n} золотой директор | {n} золотых директора | {n} золотых директоров',
  director: 'директоров | директор | директора | директоров',
  '{n} director': '0 директоров | {n} директор | {n} директора | {n} директоров',

  information_text: 'Океан — крупнейший водный объект, составляющий часть Мирового океана, расположенный среди материков, обладающий системой циркуляции вод и другими специфическими особенностями.',
  information_slogan_1: 'Форум — это праздник, \nбизнес, признание \nи вот это все.',
  information_slogan_2: 'Океан — это \nнаша концепция, \nи сама наша суть.',

  banner_1_pretitle: 'Новинка Coral Club',
  banner_1_title: 'Oceanmin',
  banner_1_subtitle: 'Сила океана \nв твоем стакане',
  banner_1_button_text: 'Купить',
  banner_1_link: '#',

  banner_2_pretitle: 'Новинка Coral Club',
  banner_2_title: 'Coralbrite',
  banner_2_subtitle: 'Твоя настоящая \nулыбка',
  banner_2_button_text: 'Купить',
  banner_2_link: '#',
  'chat action not available': 'действие недоступно'
}
