export default {
  'page not found': 'Страницата не е намерена',
  submit: 'Представя',
  'full name': 'Име',
  upload: 'изтегля',
  'new photo': 'нови снимки',
  'last name': 'фамилия',
  'first name': 'Име',
  'middle name': 'презиме',
  country: 'страна',
  city: 'град',
  interests: 'интерес',
  'favourite products': 'любими храни',
  achievements: 'напредък',
  save: 'запазя',
  saved: 'запиша',
  cancel: 'отменя',
  'edit profile': 'Редактиране на профил',
  logout: 'излизане',
  email: 'имейл',
  avatar: 'аватар',
  player: 'играч',
  'admin panel': 'администратор панел',
  users: 'ползуване',
  user: 'потребител',
  create: 'направя',
  import: 'внасям',
  language: 'език',
  'coral chips': 'коралови трески',
  'broadcasting will be available soon': 'предаването ще бъде достъпно по-късно',
  'chat action not available': 'действието не е налице'
}
