
<template>
  <div
    :id="`v-step-${hash}`"
    :ref="`v-step-${hash}`"
    :class="{ 'v-step--sticky': isSticky }"
    class="v-step"
  >
    <slot name="header">
      <div v-if="step.header" class="v-step__header">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="step.header.title"
          v-html="step.header.title"
        />
      </div>
    </slot>

    <slot name="content">
      <div class="v-step__content">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="step.content"
          v-html="step.content"
        />
        <div v-else>
          This is a demo step! The id of this step is {{ hash }} and it targets {{ step.target }}.
        </div>
      </div>
    </slot>

    <slot name="actions">
      <div class="v-step__buttons">
        <button
          v-if="!isLast && isButtonEnabled('buttonSkip')"
          class="v-step__button v-step__button-skip"
          @click.prevent="skip"
        >
          {{ labels.buttonSkip }}
        </button>
        <button
          v-if="!isFirst && isButtonEnabled('buttonPrevious')"
          class="v-step__button v-step__button-previous"
          @click.prevent="previousStep"
        >
          {{ labels.buttonPrevious }}
        </button>
        <button
          v-if="!isLast && isButtonEnabled('buttonNext')"
          class="v-step__button v-step__button-next"
          @click.prevent="nextStep"
        >
          {{ labels.buttonNext }}
        </button>
        <button
          v-if="isLast && isButtonEnabled('buttonStop')"
          class="v-step__button v-step__button-stop"
          @click.prevent="finish"
        >
          {{ labels.buttonStop }}
        </button>
      </div>
    </slot>

    <div
      class="v-step__arrow"
      :class="{ 'v-step__arrow--dark': step.header && step.header.title }"
      data-popper-arrow
    />
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core'
import jump from 'jump.js'
import sum from 'hash-sum'
import { DEFAULT_STEP_OPTIONS, HIGHLIGHT } from '../shared/constants'

export default {
  name: 'VStep',
  props: {
    step: {
      type: Object,
      default: () => ({})
    },
    previousStep: {
      type: Function,
      default: () => ({})
    },
    nextStep: {
      type: Function,
      default: () => ({})
    },
    stop: {
      type: Function,
      default: () => ({})
    },
    skip: {
      type: Function,
      default: () => {
        this.stop()
      }
    },
    finish: {
      type: Function,
      default: () => {
        this.stop()
      }
    },
    isFirst: {
      type: Boolean
    },
    isLast: {
      type: Boolean
    },
    direction: {
      type: String,
      default: () => 'RIGHT'
    },
    labels: {
      type: Object,
      default: () => ({})
    },
    enabledButtons: {
      type: Object,
      default: () => ({})
    },
    highlight: {
      type: Boolean
    },
    stopOnFail: {
      type: Boolean
    },
    debug: {
      type: Boolean
    }
  },
  data () {
    return {
      hash: sum(this.step.target),
      targetElement: document.querySelector(this.step.target)
    }
  },
  computed: {
    params () {
      return {
        ...DEFAULT_STEP_OPTIONS,
        ...{ highlight: this.highlight }, // Use global tour highlight setting first
        ...{ enabledButtons: Object.assign({}, this.enabledButtons) },
        ...this.step.params // Then use local step parameters if defined
      }
    },
    /**
     * A step is considered sticky if it has no target.
     */
    isSticky () {
      return !this.step.target
    }
  },
  mounted () {
    this.createStep()
  },
  destroyed () {
    this.removeHighlight()
  },
  methods: {
    createStep () {
      if (this.debug) {
        console.info('[Vue Tour] The target element ' + this.step.target + ' of .v-step[id="' + this.hash + '"] is:', this.targetElement)
      }

      if (this.isSticky) {
        document.body.appendChild(this.$refs['v-step-' + this.hash])
      } else {
        if (this.targetElement) {
          this.enableScrolling()
          this.createHighlight()

          createPopper(
            this.targetElement,
            this.$refs['v-step-' + this.hash],
            this.params
          )
        } else {
          if (this.debug) {
            console.error('[Vue Tour] The target element ' + this.step.target + ' of .v-step[id="' + this.hash + '"] does not exist!')
          }
          this.$emit('targetNotFound', this.step)

          if (this.direction === 'LEFT') {
            this.previousStep()
          } else {
            this.nextStep()
          }

          if (this.stopOnFail) {
            this.stop()
          }
        }
      }
    },
    enableScrolling () {
      if (this.params.enableScrolling) {
        if (this.step.duration || this.step.offset) {
          const jumpOptions = {
            duration: this.step.duration || 1000,
            offset: this.step.offset || 0,
            callback: undefined,
            a11y: false
          }

          jump(this.targetElement, jumpOptions)
        } else {
          // Use the native scroll by default if no scroll options has been defined
          this.targetElement.scrollIntoView({ behavior: 'smooth' })
        }
      }
    },
    isHighlightEnabled () {
      if (this.debug) {
        console.info(`[Vue Tour] Highlight is ${this.params.highlight ? 'enabled' : 'disabled'} for .v-step[id="${this.hash}"]`)
      }
      return this.params.highlight
    },
    createHighlight () {
      if (this.isHighlightEnabled()) {
        document.body.classList.add(HIGHLIGHT.classes.active)
        const transitionValue = window.getComputedStyle(this.targetElement).getPropertyValue('transition')

        // Make sure our background doesn't flick on transitions
        if (transitionValue !== 'all 0s ease 0s') {
          this.targetElement.style.transition = `${transitionValue}, ${HIGHLIGHT.transition}`
        }

        this.targetElement.classList.add(HIGHLIGHT.classes.targetHighlighted)
        // The element must have a position, if it doesn't have one, add a relative position class
        if (!this.targetElement.style.position) {
          this.targetElement.classList.add(HIGHLIGHT.classes.targetRelative)
        }
      } else {
        document.body.classList.remove(HIGHLIGHT.classes.active)
      }
    },
    removeHighlight () {
      if (this.isHighlightEnabled()) {
        const target = this.targetElement
        if (!target) return
        const currentTransition = this.targetElement.style.transition
        this.targetElement.classList.remove(HIGHLIGHT.classes.targetHighlighted)
        this.targetElement.classList.remove(HIGHLIGHT.classes.targetRelative)
        // Remove our transition when step is finished.
        if (currentTransition.includes(HIGHLIGHT.transition)) {
          setTimeout(() => {
            target.style.transition = currentTransition.replace(`, ${HIGHLIGHT.transition}`, '')
          }, 0)
        }
      }
    },
    isButtonEnabled (name) {
      // eslint-disable-next-line no-prototype-builtins
      return this.params.enabledButtons.hasOwnProperty(name) ? this.params.enabledButtons[name] : true
    }
  }
}
</script>

<style lang="scss" scoped>
.v-step {
  background: #fff;
  color: #222;
  font-family: "Roboto", sans-serif;
  line-height: 1.4;
  font-size: 0.85rem;
  max-width: 420px;
  border-radius: 7px;
  box-shadow: 0 15px 40px -10px rgba(#000, 0.3);
  padding: 1rem;
  pointer-events: auto;
  text-align: center;
  z-index: 10000;

  @media screen and (max-width: 768px) {
    max-width: 360px;
  }
}

.v-step__arrow,
.v-step__arrow::before {
  position: absolute;
  width: 10px;
  height: 10px;
  background: inherit;
}

.v-step__arrow {
  visibility: hidden;

  &--dark {
    &::before {
      background: #454d5d;
    }
  }
}

.v-step__arrow::before {
  visibility: visible;
  background: #fff !important;
  content: "";
  transform: rotate(45deg);
  margin-left: -5px;
}

.v-step--sticky {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .v-step__arrow {
    display: none;
  }
}

.v-step[data-popper-placement^="top"] > .v-step__arrow {
  bottom: -5px;
}

.v-step[data-popper-placement^="bottom"] > .v-step__arrow {
  top: -5px;
}

.v-step[data-popper-placement^="right"] > .v-step__arrow {
  left: -5px;
}

.v-step[data-popper-placement^="left"] > .v-step__arrow {
  right: -5px;
}

/* Custom */

.v-step__header {
  margin: -1rem -1rem 0.75rem;
  padding: 0.4rem 0.5rem;
  border-bottom: 1px solid #eee;
  font-weight: bold;
  font-size: 1.2rem;
  font-family: "BebasNeuePro", sans-serif;
  background-color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
}

.v-step__content {
  color: #444;
  text-align: left;
  margin: 0 0 1rem;

  &::v-deep p {
    margin-bottom: 0.3rem;
  }
}

.v-step__button {
  background: var(--main-primary-background-color);
  border: 2px solid transparent;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-family: "BebasNeuePro", sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 1.1rem;
  line-height: 1;
  outline: none;
  margin: 0 0.2rem;
  padding: 0.6rem 0.8rem;
  text-align: center;
  text-decoration: none;
  transition: all 0.2s ease;
  vertical-align: middle;
  white-space: nowrap;

  &:hover {
    color: #fff;
  }
}

.v-step__button-previous {
  background-color: transparent !important;
  border: 2px solid var(--main-primary-background-color) !important;
  color: var(--main-primary-background-color) !important;

  &:hover {
    background-color: var(--main-primary-background-color) !important;
    color: #fff !important;
  }
}

.v-step__button-skip {
  background-color: transparent !important;
  color: var(--main-primary-background-color) !important;

  &:hover {
    background-color: #f3f3f3 !important;
    color: var(--main-primary-background-color) !important;
  }
}
</style>
