<template>
  <v-avatar
    :size="size"
    :tile="tile"
    height="100%"
    width="100%"
  >
    <img
      style="object-fit: cover;
      object-position: center;"
      :alt="alt"
      :src="image"
      @error="handleError"
    >
  </v-avatar>
</template>

<script>
import { getLinkByEvent } from '@/utils/mediaHelpers'

export default {
  props: {
    src: {
      type: [String, Object],
      default: null
    },

    alt: {
      type: String,
      default: ''
    },

    size: {
      type: String,
      default: ''
    },

    tile: {
      type: Boolean
    },

    small: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({ attempt: 0 }),

  computed: {
    image () {
      const select = this.src ? this.attempt : this.attempt + 1
      let url
      if (select <= 0) {
        // Задана аватарка, а попыток загрузки ещё не было. Попытаемся
        // установить её.
        url = this.src
      } else if (select <= 1) {
        // Аватарка не задана или не найдена. Пытаемся установить заглушку,
        // специфичную для мероприятия.
        if (this.small) {
          url = getLinkByEvent('assets/img/theme/person-plug-mini.png')
        } else {
          url = getLinkByEvent('assets/img/theme/person-plug.png')
        }
      } else {
        // Не удалось загрузить ни аватарку, ни специфичную заглушку. Тогда
        // пытаемся установить заглушку, общую для всех мероприятий.
        if (this.small) {
          url = require('@/assets/img/theme/person-plug-mini.png')
        } else {
          url = require('@/assets/img/theme/person-plug.png')
        }
      }
      return url
    }
  },

  watch: {
    src () {
      // Картинка поменялась, надо сбросить счётчик попыток загрузки.
      this.attempt = 0
    }
  },

  methods: {
    handleError () {
      this.attempt++
    }
  }
}
</script>
